// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_vqwX7{background-color:var(--color-base);display:flex;flex-grow:0;height:100%}.controls_PdRXf{align-items:flex-end;bottom:1rem;display:flex;position:absolute;right:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_vqwX7",
	"controls": "controls_PdRXf"
};
module.exports = ___CSS_LOADER_EXPORT___;
