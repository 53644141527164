import { render, staticRenderFns } from "./Corporate.vue?vue&type=template&id=bed1c9dc&scoped=true"
import script from "./Corporate.vue?vue&type=script&lang=js"
export * from "./Corporate.vue?vue&type=script&lang=js"
import style0 from "./Corporate.vue?vue&type=style&index=0&id=bed1c9dc&prod&module=true&lang=css"
import style1 from "./Corporate.vue?vue&type=style&index=1&id=bed1c9dc&prod&scoped=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "bed1c9dc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EmptyState: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/EmptyState/EmptyState.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,PushButton: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/PushButton/PushButton.vue').default,CompositionSpacer: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,CompositionLayoutSingle: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionLayoutSingle.vue').default,Composition: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/Composition.vue').default})
