
import CorporateDiagram from "~/graphql/Company/CorporateDiagram.gql";
import expand from "~/components/svg/expand.vue";
import vis from "vis-network";

export default {
  components: { expand },
  apollo: {
    companyStructure: {
      query: CorporateDiagram,
      variables() {
        return {
          depth: 20,
          cvrNumber: this.owner.cvrNumber,
        };
      },
      skip() {
        return !this.owner;
      },
    },
  },
  props: {
    owner: { type: Object, required: true },
    fixedHeight: { type: String, default: "calc(100vh - 290px)" },
  },
  data() {
    return {
      hasEmitted: false,
      nodes: [],
      edges: [],
      defaultScale: 0,
      network: undefined,
      network_options: {
        edges: {
          hoverWidth: function (width) {
            return width;
          },
          selectionWidth: function (width) {
            return width;
          },
          labelHighlightBold: false,
          color: getComputedStyle(document.documentElement).getPropertyValue("--color-black-900").replace(" ", ""),
          font: {
            color: getComputedStyle(document.documentElement).getPropertyValue("--color-black-900").replace(" ", ""),
            size: 7, // px
            face: "GerstnerProgrammFSL",
            background: getComputedStyle(document.documentElement).getPropertyValue("--color-base").replace(" ", ""),
            align: "horizontal",
            strokeWidth: 0,
            bold: {
              color: getComputedStyle(document.documentElement).getPropertyValue("--color-black-900").replace(" ", ""),
              size: 14, // px
              face: "arial",
              vadjust: 0,
              mod: "bold",
            },
          },
        },
        nodes: {
          shape: "image",
          shadow: {
            enabled: false,
            color: "rgba(220, 231, 243, 0.7)",
            size: 20,
            x: 1,
            y: 1,
          },
        },
        layout: {
          hierarchical: {
            enabled: true,
            sortMethod: "directed",
            nodeSpacing: 130,
            levelSeparation: 80,
            parentCentralization: true,
            direction: "DU",
            shakeTowards: "roots",
            blockShifting: false,
            edgeMinimization: false,
          },
        },
        physics: { enabled: false },
        interaction: {
          hover: true,
          dragNodes: false,
          selectable: true,
          dragView: true,
          zoomView: true,
        },
      },
    };
  },
  computed: {
    showDiagram() {
      return !this.$apollo.loading && this.nodes.length > 0;
    },
    getNetworkScale() {
      if (this.network) {
        return this.network.getScale();
      }
      return null;
    },
    getNetworkPos() {
      if (this.network) {
        return this.network.getViewPosition();
      }
      return null;
    },
  },
  watch: {
    companyStructure(prev) {
      this.nodes = [];
      this.edges = [];
      this.network = undefined;

      if (prev.owningCompanies.length == 0 && prev.realOwners.length == 0 && prev.foreignOwningCompanies.length == 0) {
        this.emitFinished();
      }
      this.makeNetworkData(prev);
      this.createNetwork(this.network_options);
    },

    getNetworkPos() {
      let pos = this.network.getViewPosition();
      let coord = this.network.canvasToDOM(pos);
      let scale = this.network.getScale();

      var xMin = 0;
      var xMax = 0;
      var yMin = 0;
      var yMax = 0;
      for (var i = 0; i < this.nodes.length; i++) {
        let pos = this.network.getPositions([this.nodes[i].id])[this.nodes[i].id];
        if (pos.x > xMax) xMax = pos.x;
        if (pos.x < xMin) xMin = pos.x;
        if (pos.y > yMax) yMax = pos.y;
        if (pos.y < yMin) yMin = pos.y;
      }
      let test = { x: coord.x / scale, y: coord.y / scale };
      let diagramHalfWidth = (Math.abs(xMax) + Math.abs(xMin)) / 2;
      let diagramHalfHeight = (Math.abs(yMax) + Math.abs(yMin)) / 2;

      if (pos.x > test.x + diagramHalfWidth) {
        this.moveTo(test.x + diagramHalfWidth, pos.y);
      }
      if (pos.y > test.y + diagramHalfHeight) {
        this.moveTo(pos.x, test.y + diagramHalfHeight);
      }
      if (pos.x < -test.x - diagramHalfWidth) {
        this.moveTo(-test.x - diagramHalfWidth, pos.y);
      }
      if (pos.y < -test.y - diagramHalfHeight) {
        this.moveTo(pos.x, -test.y - diagramHalfHeight);
      }
    },
  },
  methods: {
    emitFinished() {
      if (!this.hasEmitted) {
        this.$emit("finished");
        this.hasEmitted = true;
      }
    },
    moveTo(x, y, z) {
      this.network.moveTo({
        position: { x, y },
        scale: z,
      });
    },
    async fitToScale() {
      var xMin = 0;
      var xMax = 0;
      var yMin = 0;
      var yMax = 0;
      for (var i = 0; i < this.nodes.length; i++) {
        let pos = this.network.getPositions([this.nodes[i].id])[this.nodes[i].id];

        // eslint-disable-next-line no-self-assign
        pos.x > xMax ? (xMax = pos.x) : (xMax = xMax);
        // eslint-disable-next-line no-self-assign
        pos.x < xMin ? (xMin = pos.x) : (xMin = xMin);
        // eslint-disable-next-line no-self-assign
        pos.y > yMax ? (yMax = pos.y) : (yMax = yMax);
        // eslint-disable-next-line no-self-assign
        pos.y < yMin ? (yMin = pos.y) : (yMin = yMin);
      }

      let xPadding = 50;
      let yPadding = 30;
      let px = this.$refs.network.clientWidth;
      let py = this.$refs.network.clientHeight;
      let DOMTopLeft = this.network.canvasToDOM({
        x: xMin - xPadding,
        y: yMax + yPadding,
      });
      let DOMBottomRight = this.network.canvasToDOM({
        x: xMax + xPadding,
        y: yMin - yPadding,
      });
      let DOMtotal = {
        x: DOMBottomRight.x - DOMTopLeft.x,
        y: DOMTopLeft.y - DOMBottomRight.y,
      };
      let ratio = { x: px / DOMtotal.x, y: py / DOMtotal.y };
      if (yMax < 42) {
        ratio = { x: (px * 0.5) / DOMtotal.x, y: (py * 0.5) / DOMtotal.y };
      }
      ratio = ratio.x > ratio.y ? ratio.y : ratio.x;
      this.network.moveTo({
        position: { x: 0, y: 0 },
        scale: this.getNetworkScale * ratio,
        animation: {
          duration: 200,
          easingFunction: "easeInQuad",
        },
      });
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 500);
      });
    },
    zoomIn() {
      this.network.moveTo({
        scale: this.getNetworkScale + this.defaultScale * 0.1,
        animation: {
          duration: 100,
          easingFunction: "linear",
        },
      });
    },
    zoomOut() {
      if (this.getNetworkScale > this.defaultScale * 0.13)
        this.network.moveTo({
          scale: this.getNetworkScale - this.defaultScale * 0.1,
          animation: {
            duration: 100,
            easingFunction: "linear",
          },
        });
    },
    downloadCanvas() {
      // Google Analytics is retired as long as Amplitude is in use
      // this.$ga.event({
      //     eventCategory: "Actions",
      //     eventAction: "Download",
      //     eventLabel: "Corporate",
      //     eventValue: 1,
      // });
      this.amplitudeLogEvent();

      var canvas = this.$refs.network.firstElementChild.firstElementChild;
      var a = document.createElement("a");
      var name = this.capitalizeFirstLetter(this.companyStructure.name) + " Diagram.png";
      a.download = name;
      a.href = canvas.toDataURL();
      a.click();
    },
    async createNetwork() {
      let container = this.$refs.network;
      let data = {
        nodes: this.nodes,
        edges: this.edges,
      };
      if (!this.showDiagram) {
        container.style.display = "none";
        return;
      }
      this.network = new vis.Network(container, data, this.network_options);
      this.network.on("beforeDrawing", function (ctx) {
        ctx.save();
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.fillStyle = getComputedStyle(document.documentElement).getPropertyValue("--color-base").replace(" ", "");
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.restore();
      });

      await this.fitToScale();
      this.emitFinished();

      this.defaultScale = this.getNetworkScale;
      this.network.addEventListener("selectNode", (event) => {
        const nodeid = event.nodes[0];
        const node = this.nodes.find((x) => x.id == nodeid);
        if (node.cvr) {
          this.navigateToCompany(node);
        } else if (node.entityId) {
          this.navigateToPerson(node);
        }
      });
      this.network.addEventListener("hoverNode", (event) => {
        const node = this.nodes.find((x) => x.id == event.node);
        if (node.cvr || node.entityId) {
          this.network.body.container.style.cursor = "pointer";
        }
      });
      this.network.addEventListener("blurNode", () => {
        this.network.body.container.style.cursor = "default";
      });
    },
    navigateToCompany(node) {
      const search = {
        id: node.cvr,
        searchTerm: node.name,
      };
      this.$store.dispatch("search/initiateCompanySearch", {
        search,
        track: "company ownership",
      });
    },

    navigateToPerson(node) {
      const search = {
        id: node.entityId,
        searchTerm: node.name,
      };
      this.$store.dispatch("search/initiatePersonSearch", { search, track: "company ownership" });
    },

    shareRegexSplitter(share) {
      if (share) {
        return share.split("-");
      } else return [];
    },

    makeNetworkData(company, type, newShare, oldShare) {
      let sharecalc = "";
      if (newShare) {
        sharecalc = newShare;
        if (oldShare) {
          let os = this.shareRegexSplitter(oldShare);
          let ns = this.shareRegexSplitter(newShare);
          let calcSplit = [];
          os = [parseFloat(os[0]) / 100, os[1] ? parseFloat(os[1]) / 100 : 1];
          ns = [parseFloat(ns[0]) / 100, ns[1] ? parseFloat(ns[1]) / 100 : 1];
          calcSplit = [os[0] * ns[0], os[1] * ns[1]];
          calcSplit = [calcSplit[0] * 100, calcSplit[1] * 100];
          sharecalc = `${Math.round((calcSplit[0] + Number.EPSILON) * 100) / 100}-${Math.round((calcSplit[1] + Number.EPSILON) * 100) / 100}`;
        }
      }

      let svgData = {
        name: company.name,
        type: type,
        share: sharecalc,
      };
      if (company.realOwners && company.realOwners.length > 0) {
        company.realOwners.forEach((owner) => {
          this.createEdge(owner.id, company.id, owner.id, this.formatShareInRanges(owner.shareInRanges));
          //include reel owner share
          this.makeNetworkData(owner, "reel", owner.shareInRanges, sharecalc);
          //dont include reel owner share
          // this.makeNetworkData(owner, "reel");
        });
      }

      if (company.owningCompanies && company.owningCompanies.length > 0) {
        company.owningCompanies.forEach((owner) => {
          this.createEdge(owner.id, company.id, owner.company.id, this.formatShareInRanges(owner.shareInRanges));
          this.makeNetworkData(owner.company, "owning", owner.shareInRanges, sharecalc);
        });
      }

      if (company.foreignOwningCompanies && company.foreignOwningCompanies.length > 0) {
        company.foreignOwningCompanies.forEach((owner) => {
          this.createEdge(owner.id, company.id, owner.id, this.formatShareInRanges(owner.shareInRanges));
          this.makeNetworkData(owner, "foreign");
        });
      }

      let node = {
        id: company.id,
        cvr: company.cvrNumber,
        name: company.name,
        entityId: company.entityId,
        image: this.svgRenderer(svgData),
      };

      this.nodes.push(node);
    },
    createEdge(id, from, to, label) {
      this.edges.push({
        id: id,
        from: from,
        to: to,
        label: label,
      });
    },
    formatShareInRanges(range) {
      if (range == "") {
        return "0%";
      }
      let rng = range;
      rng.replace("-", " - ");

      return rng.replace("-", " - ") + "%";
    },

    svgRenderer(data) {
      var color = "";
      if (data.type === "reel") {
        color = "--color-light-turquoise";
      } else if (data.type === "owning") {
        color = "--color-info-base";
      } else if (data.type === "foreign") {
        color = "--color-light-turquoise";
      } else {
        color = "--color-blue-500";
      }
      var nameWithoutAmpersand = data.name;
      for (var i = 0; i < nameWithoutAmpersand.length; i++) {
        if (nameWithoutAmpersand[i] === "&") {
          nameWithoutAmpersand = nameWithoutAmpersand.replace("&", "og");
        }
      }
      var name = this.wrap(nameWithoutAmpersand);
      const border = getComputedStyle(document.documentElement).getPropertyValue("--color-border").replace(" ", "");
      const black = getComputedStyle(document.documentElement).getPropertyValue("--color-black-high-emphasis").replace(" ", "");
      const colval = getComputedStyle(document.documentElement).getPropertyValue(color).replace(" ", "");

      var svg = `<svg width="142" height="78" viewBox="0 0 142 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                <style> .large { font-size: 10px; font-family: Arial; font-weight: 600}.small { font-size: 8px; font-family: Arial; font-weight: 400}</style>
                <g><rect width="142" height="78" rx="2" fill="white"/>
                <rect x="0.1" y="0.1" width="141.8" height="77.8" rx="1.9" stroke="${border}" stroke-width="0.2"/>
                <path d="M0 2C0 0.895431 0.895431 0 2 0H140C141.105 0 142 0.895431 142 2V4H0V2Z" fill="${colval}"/>
                <text x="10" y="22" class="large" fill="${black}"> ${name} </text>
                <text x="10" y="66" class="small" fill="${black}"> ${data.share ? this.formatShareInRanges(data.share) : ""} </text>
                </g></svg>`;
      var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);
      return url;
    },
    capitalizeFirstLetter(string) {
      return string.toLowerCase().replace(/(?:^|[\s-/])\w/g, function (match) {
        return match.toUpperCase();
      });
    },
    wrap(string) {
      let str = this.capitalizeFirstLetter(string);
      let arr = str.split(" ");
      let compound = [];
      var l = 0;
      compound.push("");
      for (var i = 0; i < arr.length; i++) {
        if (compound[l].length + arr[i].length < 25) {
          compound[l] += arr[i] + " ";
        } else if (arr[i].length > 25) {
          if (compound[l].length > 15) {
            let val = 25 - compound[l].length;
            compound[l] += arr[i].slice(0, val) + "- ";
            compound.push("");
            l++;
            compound[l] += arr[i].slice(val, arr[i].length) + " ";
          } else {
            compound.push("");
            l++;
            compound[l] += arr[i].slice(0, 25) + "- ";
            compound.push("");
            l++;
          }
        } else {
          l++;
          compound.push("");
          compound[l] += arr[i] + " ";
        }
      }
      let str2 = "";
      for (var w = 0; w < compound.length; w++) {
        var y = 22 + 10 * w;
        str2 += `<tspan x="10" y="${y}">${compound[w]}</tspan>`;
      }
      return str2;
    },
    amplitudeLogEvent() {
      var eventProperties = {
        address: this.$store.getters["search/searchTerm"],
        name: this.owner?.name,
        type: this.owner?.ownershipText,
        cvr: this.owner?.cvrNumber,
      };
      this.$amplitude.track({ event_type: "Download - corporate", event_properties: eventProperties });
    },
  },
};
